import SocialMediaIcons from '../components/SocialMediaIcons';
import useMediaQuery from '../hooks/useMediaQuery';
import { motion } from 'framer-motion';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Landing = ({ setSelectedPage }) => {
    const isAboveLarge = useMediaQuery('(min-width: 1060px)');
    return (
        <section
            id="home"
            className="md:flex md:justify-between md:items-center gap-16 md:h-full py-10"
        >
            {/* IMAGE SECTION */}
            <div className="basis-3/5 z-10 mt-16 md:mt-36 flex justify-center md:order-2">
                {isAboveLarge ? (
                    // <div
                    //   className="relative z-0 ml-20 saturate-150 before:absolute before:-top-10 before:-left-10 before:rounded-t-[400px]
                    //   before:w-full before:max-w-[400px] md:before:max-w-[600px] before:h-full before:border-2 before:border-blue before:z-[-1]"
                    // >
                    <img
                        alt="profile"
                        className="hover:filter rounded-t-[400px]  hover:saturate-200 transition duration-500 z-10 w-full max-w-[300px] md:max-w-[400px]"
                        src="assets/profile.png"
                    />
                ) : (
                    //  </div>
                    <img
                        alt="profile"
                        className="z-10 rounded-t-[400px] w-full max-w-[400px] md:max-w-[600px]"
                        src="assets/profile.png"
                    />
                )}
            </div>

            {/* MAIN TEXT */}
            <div className="z-30 basis-2/5 mt-12 md:mt-32">
                {/* HEADINGS */}
                <motion.div
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, x: -50 },
                        visible: { opacity: 1, x: 0 }
                    }}
                >
                    <p className="text-6xl font-playfair z-10 text-center md:text-start">
                        Pensiri {''}
                        <span
                            className="xs:relative  xs:font-semibold z-20 
              before:absolute before:-left-[25px] before:-top-[70px] before:z-[-1]"
                        >
                            Yogi
                        </span>
                    </p>

                    <p className="mt-10 mb-4 md:mb-6 text-xl text-center md:text-left  md:text-2xl">
                        I am a Developer and Project Manager{' '}
                    </p>
                    <p className="mb-6 text-xs md:text-sm">
                        As a dedicated developer with a supportive approach, I
                        blend my technical expertise with supportive
                        contributions. I excel at turning complex concepts into
                        easy-to-use platforms. Through experience, I've learned
                        to connect development with business, leveraging my
                        expertise to deliver impactful project outcomes.
                    </p>
                </motion.div>

                {/* CALL TO ACTIONS */}
                <motion.div
                    className="flex mt-5 justify-center md:justify-start"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.2, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, x: -50 },
                        visible: { opacity: 1, x: 0 }
                    }}
                >
                    <AnchorLink
                        className="bg-gradient-rainblue text-deep-blue rounded-sm py-3 px-7 font-semibold
              hover:bg-blue hover:text-white transition duration-500"
                        onClick={() => setSelectedPage('contact')}
                        href="#contact"
                    >
                        Contact Me
                    </AnchorLink>
                    <a
                        href="./skills/CV-Pensir-Yogi.pdf"
                        download="Pensiri_Yogi_CV.pdf"
                        className="rounded-r-sm bg-gradient-rainblue py-0.5 pr-0.5"
                        target='_blank'
                    >
                        <div className="bg-deep-blue hover:text-red transition duration-500 w-full h-full flex items-center justify-center px-10 font-playfair">
                            Download CV
                        </div>
                    </a>
                </motion.div>

                <motion.div
                    className="flex mt-5 justify-center md:justify-start"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ delay: 0.4, duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, x: -50 },
                        visible: { opacity: 1, x: 0 }
                    }}
                >
                    <SocialMediaIcons />
                </motion.div>
            </div>
        </section>
    );
};

export default Landing;
