import React from 'react';
import LineGradient from '../components/LineGradient';
import { motion } from 'framer-motion';
import useMediaQuery from '../hooks/useMediaQuery';

const skillVariant = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 }
};
const container = {
    hidden: {},
    visible: {
        transition: {
            staggerChildren: 0.2
        }
    }
};
const Project = ({ title }) => {
    const skillTitle = title.split(' ').join('-').toLowerCase();

    return (
        <motion.div
            variants={skillVariant}
            className="relative w-16 md:w-16 rounded-2xl"
        >
            <img src={`../skills/${skillTitle}.svg`} alt={skillTitle} />
        </motion.div>
    );
};

const MySkills = () => {
    const isAboveLarge = useMediaQuery('(min-width: 1060px)');

    return (
        <section id="skills" className="pt-10 pb-24">
            <div className="md:flex  mt-32">
                <motion.div
                    className="mx-auto text-center"
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.5 }}
                    transition={{ duration: 0.5 }}
                    variants={{
                        hidden: { opacity: 0, x: -50 },
                        visible: { opacity: 1, x: 0 }
                    }}
                >
                    <p className="font-playfair font-semibold text-4xl mb-5">
                        My <span className="text-red">Skills</span>
                    </p>
                    <div className="flex justify-center mt-5 mb-20 ">
                        <LineGradient width="w-[220px]" />
                    </div>
                    {/* <p className="mt-10 mb-10">
                        As a dedicated developer with a supportive approach, I
                        blend my technical expertise with supportive
                        contributions. I excel at turning complex concepts into
                        easy-to-use platforms. Through experience, I've learned
                        to connect development with business, leveraging my
                        expertise to deliver impactful project outcomes.
                    </p> */}
                </motion.div>
            </div>

            {/* PROJECTS */}
            <div className="flex justify-center">
                <motion.div
                    className="grid  grid-cols-3 md:grid-cols-6 gap-16 md:gap-20"
                    variants={container}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.2 }}
                >
                    <Project title="react" />
                    <Project title="next" />
                    <Project title="typescript" />
                    <Project title="javascript" />
                    <Project title="laravel" />
                    <Project title="php" />
                    <Project title="mysql" />
                    <Project title="css" />
                    <Project title="graphql" />
                    <Project title="tailwind" />
                    <Project title="bootstrap" />
                    <Project title="wordpress" />
                    <Project title="git" />
                </motion.div>
            </div>
        </section>
    );
};

export default MySkills;
