import React from 'react';
import { motion } from 'framer-motion';
import LineGradient from '../components/LineGradient';
import { expriancelist } from '../constant/expriance';

const Experience = () => {
    return (
        <section id="experience" className="pt-48 pb-48">
            {/* HEADINGS */}
            <motion.div
                className="mx-auto"
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.5 }}
                variants={{
                    hidden: { opacity: 0, y: -50 },
                    visible: { opacity: 1, y: 0 }
                }}
            >
                <div className="flex flex-col justify-center md:justify-start">
                    <p className="font-playfair font-semibold text-4xl text-center md:text-left">
                        <span className="text-red"></span>Experience
                    </p>
                    <div className="mt-5 mb-20 flex justify-center md:justify-start">
                        <LineGradient width="w-[240px] md:w-3/12" />
                    </div>
                </div>
            </motion.div>
            <ol className="relative border-s border-gray-200 dark:border-gray-700 ">
                {expriancelist.map((experience, index) => (
                    <motion.div
                        key={index}
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true, amount: 0.5 }}
                        transition={{ duration: 0.5 }}
                        variants={{
                            hidden: { opacity: 0, y: -50 },
                            visible: { opacity: 1, y: 0 }
                        }}
                    >
                        <li className="mb-10 ms-4 flex flex-col items-start">
                            <div className="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white dark:border-gray-900 dark:bg-gray-700"></div>

                            <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                                {experience.year}
                            </time>
                            <h3 className="text-lg text-left font-semibold text-white dark:text-white">
                                {experience.position}
                            </h3>
                            <h4 className="text-left">{experience.company}</h4>
                            <div
                                className="text-base font-normal text-gray-500 dark:text-gray-400 text-left"
                                dangerouslySetInnerHTML={{
                                    __html: experience.description
                                }}
                            />
                        </li>
                    </motion.div>
                ))}
            </ol>
        </section>
    );
};

export default Experience;
