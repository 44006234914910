export const expriancelist = [
    {
        year: 'Aug 2023 - Mar 2024',
        company: 'Orange Cap Innovative Co., Ltd.',
        position: 'IT Project Manager',
        description:
            '<ui><li>- Worked closely with cross-functional teams (business, development, operations, UX/UI) and third-party companies (KBANK, KBTG) to build a travel application platform (TAGTHAi).</li><li- Lead a team of 10 people on a travel project including manual testers, frontend developers, backend developers, and UX/ UI Designers</li><li>- Developed project scopes and objectives, involving all relevant stakeholders and ensuring technical feasibility</li><li>- Supported in prioritizing the product backlog and facilitated alignment between business requirements and technical solutions within the development team.</li></ui>'
    },
    {
        year: 'Aug 2021 - Aug 2023',
        company: 'Orange Cap Innovative Co., Ltd.',
        position: 'Frontend Developer',
        description:
            '<ui><li>- Collaborated with Project Manager and UI/UX designers, providing feedback and transforming wireframes into high-quality, user-centric applications.</li><li>- Collaborated with backend and mobile developers to integrate frontend interfaces with backend functionalities, ensuring data flows smoothly between the system.</li><li>- Collaborated with developers and Project Manager on planning, doing analysis tasks, and timeline</li><li>- Developed systems for travel, medical, and business websites by using React, Next.Js, WordPress, Tailwind CSS, and connect API by using NodeJs or GraphQL</li></ui>'
    },
    {
        year: 'Dec 2020 - Aug 2021',
        company: 'Modern HR Thailand',
        position: 'Web Developer',
        description:
            '<ui><li>- Worked closely with clients to deliver desired specifications for the website, managed budgets, and provided continuous modifications and suggestions on ways to improve the product.</li><li>- Successfully created an eCommerce website for HR companies, facilitating transactions for paperwork, training courses, and consultations.</li> <li>- Developed systems using the PHP Laravel Framework, ensuring seamless functionality and efficient operations.</li><li>- Designed and implemented MySQL databases, ensuring effective data management throughout projects.</li></ui>'
    },
    {
        year: 'Mar 2013 - Jun 2020 ',
        company: 'Department of Computer Engineering,Chiang Mai University ',
        position: 'Software Developer',
        description:
            '<ui><li>- Analyzed problems and requirements leading to design system flow</li><li>- Developed software for 30+ supporting officers, instructors and students</li><li>- Designed system and database</li><li>- Designed advertisement banners and brochures</li><li>- Integrated Student Research Management System and E-document system</li><li>- Organized ACM - ICPC University Programming Contest North Region, Thailand. Installed software and verified candidates in contest system</li><li>- Provided administration for computer engineering department Facebook</li><li>- Taught undergraduate courses in C++ , Python, VUE.js, React, Express, Javascript</li><li>- Utilized PHP, Laravel Framework, MySQL and Wordpress</li></ui>'
    },
    {
        year: 'Sep 2010 - Mar 2013',
        company: 'IT Department, Faculty of Engineering, Chiang Mai University',
        position: 'Software Developer',
        description:
            '<ui><li>- Analyzed and resolved problems in the workplace through new technology</li><li>- Designed system, system flow and databases</li><li>- Worked in information technology and innovation education section</li><li>- Developed systems using PHP, MySQL</li></ui>'
    },
    {
        year: 'Feb 2009 - Aug 2010',
        company: 'Chiangmai Webdesign',
        position: 'Software Developer',
        description:
            '<ui><li>- Developed Website from user requirements</li><li>- Designed Database</li><li>- Tested and UAT trained for users</li></ui>'
    }
];
