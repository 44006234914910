const SocialMediaIcons = () => {
    return (
        <div className="flex justify-center md:justify-start my-10 gap-7 items-center">
            <a
                className="hover:opacity-50 transition duration-500"
                href="https://www.linkedin.com/in/pensirik/"
                target="_blank"
                rel="noreferrer"
            >
                <img alt="linkedin-link" src="../assets/linkedin.png" />
            </a>
            <a
                className="hover:opacity-50 transition duration-500"
                href="https://github.com/Pensirik"
                target="_blank"
                rel="noreferrer"
            >
                <img alt="github" src="../assets/github-48.png" width={32} height={32} />
            </a>
        </div>
    );
};

export default SocialMediaIcons;
